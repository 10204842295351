import _ from 'lodash';
import { searchText } from "../../../../../../lib/text-utils";
import { IWiml_v1, RuntimeWimlComponentProps_v1 } from "../../types";
import { getListSiteData_v1_0_0 } from "../../v1.0.0/site-data/retrieval";
import { renderChildComponentSiteDataPropertyValue_v1_2_0 } from "../../v1.2.0/theme-data/renderer/render-components";
import { SearchQuery_v1, SearchResultCollection_v1 } from "../../types";
import { getTextFromHtml } from '../../../../../../lib/text-utils';
import { getChildComponentPropertyValueSiteData_v1_2_0 } from '../../v1.2.0/site-data/retrieval';

export function searchSiteData_v1_4_0(query: SearchQuery_v1, wimlData: IWiml_v1) {
    const retVal: { results: SearchResultCollection_v1 } = {
        results: {
            ids: [],
            items: {}
        }
    };
    // Process lists according to rules
    const lists = query.rules.location.components.items.lists.items;
    for (const listId of Object.keys(lists)) {
        const list = lists[listId];
        const listComponentRules = list.components.items;

        // todo make wiml unaware of website class
        const listSiteData = getListSiteData_v1_0_0(wimlData, listId);

        for (const listItemId of listSiteData.items.ids || []) {
            let foundMatch = false;
            let title = '';
            let description = null;

            const sortedComponentIds = _
                .sortBy(Object.keys(listComponentRules),
                    [(id) => id.startsWith('heading')],
                    ['desc']);
            for (const componentId of sortedComponentIds) {
                const componentRules = listComponentRules[componentId];
                for (const propertyName of Object.keys(componentRules)) {
                    if (componentRules[propertyName] === true) {
                        const content = getChildComponentPropertyValueSiteData_v1_2_0(
                            wimlData,
                            componentId,
                            propertyName,
                            null, listId, listItemId
                        );

                        if (content) {
                            if ((componentId.startsWith('heading'))) {
                                // If it's a heading, use as title
                                // note - headings are sorted last on purpose
                                // so that if text or something else is found first, it will be used as description and just include the heading as title
                                if (foundMatch) {
                                    title = content;
                                } else {
                                    const searchResult = searchText(query.searchTerm, content);
                                    if (searchResult.found) {
                                        foundMatch = true;
                                        title = content;
                                    }
                                }
                            } else {
                                let searchContent;
                                if (componentId.startsWith('text')) {
                                    // text will be html
                                    searchContent = getTextFromHtml(content);
                                } else {
                                    searchContent = content;
                                }
                                const searchResult = searchText(query.searchTerm, searchContent);

                                if (searchResult.found) {
                                    foundMatch = true;
                                    description = searchResult.snippet;
                                }
                            }
                        }

                    }
                }
            }

            if (foundMatch) {
                // Get URL from link__default component
                let url: string = null;

                const propsForRetrieval = {
                    componentId: 'link',
                    wimlData,
                    dataRetrievalListId: listId,
                    dataRetrievalListItemId: listItemId,
                } as any as RuntimeWimlComponentProps_v1;

                const content = renderChildComponentSiteDataPropertyValue_v1_2_0(
                    propsForRetrieval,
                    'url',
                );
                if (content) {
                    url = content;
                }
                // Add to results
                const resultId = listItemId;
                retVal.results.ids.push(resultId);
                retVal.results.items[resultId] = {
                    title,
                    description,
                    url,
                    meta: {
                        location: {
                            listId,
                            listItemId
                        }
                    }
                };
            }
        }
    }
    return retVal;
}
