import { IWiml_v1, ListItemToCheckoutItemSiteData_v1, SiteDataListItem_v1 } from "../../types";
import { getListItemSiteData_v1_0_0 } from "../../v1.0.0/site-data/retrieval";
import { ComponentKey } from "../../v1.0.0/types";
import { listItemComponentCheckoutRules_v1_3_0 } from "../types";

export function convertListItemToCheckoutItemSiteData_v1_3_0(wimlData: IWiml_v1, listId: string, listItemId: string, accountActive: boolean) {
    const retVal: ListItemToCheckoutItemSiteData_v1 = { errors: {} as Record<string, string>, valid: true, data: {} as Record<string, any>, meta: { location: { listId, listItemId } } };

    const listItemData = getListItemSiteData_v1_0_0(wimlData, listId, listItemId);

    // todo remove and put into own v1.3.0
    const rulesByWimlVer = listItemComponentCheckoutRules_v1_3_0 as Record<string, any>;
    const checkoutData = (Object.keys(rulesByWimlVer) as string[]).reduce((acc, componentKey) => {
        const rules = rulesByWimlVer[componentKey];
        const fieldValues = Object.keys(rules).reduce((acc2, fieldName) => {
            const fieldValue = listItemData?.components?.items?.[componentKey]?.[fieldName]?.data?.value;
            if (fieldValue) {
                acc2[fieldName] = fieldValue;
            } else {
                retVal.valid = false;
                retVal.errors[fieldName] = `${componentKey}.${fieldName} is required`;
            }
            return acc2;
        }, {} as Record<string, any>);

        acc[componentKey] = fieldValues;

        return acc;
    }, {} as Record<ComponentKey, any>);

    const checkoutOptionsData = {} as Record<string, any>;
    // without this explicit type cast, nextjs build todo remove this

    const physicalTypeProductOptionListItemId = Object.entries(wimlData.siteData.components.items.lists.items.list__product_options?.items?.items || {} as Record<string, SiteDataListItem_v1>)
        .find(([k, v]) => /physical/i.test(v.components?.items?.heading__title?.content?.data?.value as string))?.[0];

    if (physicalTypeProductOptionListItemId && listItemData.relationships?.lists?.items?.list__product_options?.ids?.includes(physicalTypeProductOptionListItemId)) {
        checkoutOptionsData.type = 'physical';
    } else {
        checkoutOptionsData.type = 'digital';
    }

    // extra validation
    if (!accountActive) {
        retVal['valid'] = false;
        retVal['errors']['accountActive'] = 'account is not active';
    }

    retVal.data = checkoutData;
    retVal.data.options = checkoutOptionsData;

    return retVal;
} 
