// todo v1.4 refer to connie on how dark(a yellow #f7e21c) is not dark. instead change the variables
// color-text -> black color
// color-primary -> light blue
// color-secondary -> darker blue
// color-accent -> red
// again refer to https://app.wildinkpages.com/manage/connie -> https://app.wildinkpages.com/manage/template8

export const cssGlobalVariables_v1_4_0 = `
/*
Footer inherits Header.
Header inherits Body.

Methodology: do a 2-pass when creating vars. 
First pass should fill in global vars or add new custom vars.
Second pass should replace duplicates.
*/

/* Group 0 - Extra Variables - do not prefix with "--global" Example below: */
/* --color-secondary: */

/* Group 1 - Body Colors */
--global-body-bg-color: #fff;
--global-body-text-content-color: #07031A;
--global-body-text-link-color:  #89CFF0;
--global-body-button-bg-color: var(--global-body-text-link-color);
--global-body-button-text-color: var(--global-body-bg-color);
--global-body-icon-color: var(--global-body-text-content-color);
--global-body-hr-line-color: var(--global-body-text-content-color);

/* Group 2 - Header Colors */
--global-header-bg-color: var(--global-body-bg-color);
--global-header-text-content-color: var(--global-body-text-content-color);

/* Group 3 - Footer Colors */
--global-footer-bg-color: var(--global-header-bg-color);
--global-footer-text-content-color: var(--global-header-text-content-color);

/* Group 4 - Body Fonts */
--global-body-text-heading-font: "Open Sans", sans-serif;
--global-body-text-sub-heading-font: var(--global-body-text-heading-font);
--global-body-text-content-font: var(--global-body-text-heading-font);
`;
// if extra vars are needed for header/footer, e.g. font, hr-line-color, etc. they'll have to be added here.

export const cssHeaderFooterStyle_v1_4_0 = `header .heading, header .sub-heading, header .navigation {
  color: var(--global-header-text-content-color);
}

footer .heading, footer .sub-heading {
  color: var(--global-footer-text-content-color);
}`;
