import { IThemeDataToReactConverter_v1 } from '../../types';
import { _markupToComponentThemeData_v1_1_0 } from './compiler';
import { ComponentThemeData_v1_1_0 } from './compiler/types';
import { convertThemeDataToReact_v1_1_0 } from './renderer/render-components';


export class ConvertThemeDataToReact_v1_1_0 implements IThemeDataToReactConverter_v1 {
    public convertComponentThemeDataToRenderThemeData_v1(themeData: ComponentThemeData_v1_1_0) {
        return convertThemeDataToReact_v1_1_0(themeData);
    }
    public convertMarkupToComponentThemeData_v1(wimlString: string) {
        return _markupToComponentThemeData_v1_1_0(wimlString);
    }
}
