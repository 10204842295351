import { listItemComponentCheckoutRules_v1_3_0 } from "../../v1.3.0/types";
import { getListInstanceComponentThemeDataId_v1_0_0, getPageComponentThemeDataId_v1_0_0 } from "../theme-data/compiler/retrieval";

import { convertObjectToArray } from '../../../../../../lib/object-utils';
import { IWiml_v1, ListItemToCheckoutItemSiteData_v1, RuntimeWimlComponentProps_v1, SiteDataListItem_v1, } from "../../types";
import { ComponentKey, listItemComponentCheckoutRules_v1_0_0 } from '../types';

export function getListChildComponentPropertyValueSiteData_v1_0_0(wimlData: IWiml_v1, componentKey: string, propertyName: string, componentType: string, listId: string, listItemId: string) {
    if (!listId || !listItemId) throw new Error('listId and listItemId are required for getListChildComponentPropertyValueSiteData_v1_0_0');
    const camlizedComponentId = getListInstanceComponentThemeDataId_v1_0_0(componentKey, componentType);

    const listItemData = getListItemSiteData_v1_0_0(wimlData, listId, listItemId);
    const propertyValue = listItemData?.components?.items?.[camlizedComponentId]?.[propertyName]?.data?.value;

    return propertyValue;
}

export function getPageChildComponentPropertyValueSiteData_v1_0_0(wimlData: IWiml_v1, componentKey: string, propertyName: string, componentType: string, pageId: string) {
    if (!pageId) throw new Error('pageId is required for getPageComponentPropertyValue: ' + propertyName);
    const { pageId: newPageId, camlizedComponentId } = getPageComponentThemeDataId_v1_0_0(pageId, componentKey, componentType);

    const propertyValue = wimlData.siteData?.components.items?.pages?.items?.[newPageId]?.components?.items?.[camlizedComponentId]?.[propertyName]?.data?.value;

    return propertyValue;
}

export function convertListItemToCheckoutItemSiteData_v1_0_0(wimlData: IWiml_v1, listId: string, listItemId: string, accountActive: boolean) {
    const retVal: ListItemToCheckoutItemSiteData_v1 = { errors: {} as Record<string, string>, valid: true, data: {} as Record<string, any>, meta: { location: { listId, listItemId } } };

    const listItemData = getListItemSiteData_v1_0_0(wimlData, listId, listItemId);

    // todo remove and put into own v1.3.0
    const rulesByWimlVer = listItemComponentCheckoutRules_v1_0_0 as Record<string, any>;
    const checkoutData = (Object.keys(rulesByWimlVer) as string[]).reduce((acc, componentKey) => {
        const rules = rulesByWimlVer[componentKey];
        const fieldValues = Object.keys(rules).reduce((acc2, fieldName) => {
            const fieldValue = listItemData?.components?.items?.[componentKey]?.[fieldName]?.data?.value;
            if (fieldValue) {
                acc2[fieldName] = fieldValue;
            } else {
                retVal.valid = false;
                retVal.errors[fieldName] = `${componentKey}.${fieldName} is required`;
            }
            return acc2;
        }, {} as Record<string, any>);

        acc[componentKey] = fieldValues;

        return acc;
    }, {} as Record<ComponentKey, any>);

    const checkoutOptionsData = {} as Record<string, any>;
    // without this explicit type cast, nextjs build todo remove this


    const physicalTypeProductOptionListItemId = Object.entries(wimlData.siteData.components.items.lists.items.productOptions?.items?.items || {} as Record<string, SiteDataListItem_v1>)
        .find(([k, v]) => /physical/i.test(v.components?.items?.heading?.content?.data?.value as string))?.[0];

    if (physicalTypeProductOptionListItemId && listItemData.relationships?.lists?.items?.productOptions?.ids?.includes(physicalTypeProductOptionListItemId)) {
        checkoutOptionsData.type = 'physical';
    } else {
        checkoutOptionsData.type = 'digital';
    }

    // extra validation
    if (!accountActive) {
        retVal['valid'] = false;
        retVal['errors']['accountActive'] = 'account is not active';
    }


    retVal.data = checkoutData;
    retVal.data.options = checkoutOptionsData;

    return retVal;
}

// todo - wiml should be unaware of website
export function getListItemSiteData_v1_0_0(wimlData: IWiml_v1, listId: string, listItemId: string) {
    const retVal = getListSiteData_v1_0_0(wimlData, listId)?.items?.items?.[listItemId];

    return retVal;
}
// todo - wiml should be unaware of website
export function getListSiteData_v1_0_0(wimlData: IWiml_v1, listId: string) {
    return wimlData.siteData?.components.items?.lists?.items?.[listId];
}

export function getListItemsSiteDataArray_v1_0_0(props: RuntimeWimlComponentProps_v1, listId: string) {
    const propertyValue = getListSiteData_v1_0_0(props.wimlData, listId)?.items?.items;
    const itemsArray = convertObjectToArray(propertyValue);

    const retVal = itemsArray;

    return retVal;
}

