import { MarkupToReactConverter_v1 } from "./theme-data-v1";
import { IWiml_v1, WimlVer, WimlImplClass_v1, ThemeData_v1, SiteData_v1, IWimlImpl_v1, SearchQuery_v1 } from "./types";
import { WimlImpl_v1_0_0 } from "./v1.0.0/wiml-v1-0-0";
import { WimlImpl_v1_1_0 } from "./v1.1.0/wiml-v1-1-0";
import { WimlImpl_v1_2_0 } from "./v1.2.0/wiml-v1-2-0";
import { WimlImpl_v1_3_0 } from "./v1.3.0/wiml-v1-3-0";
import { WimlImpl_v1_4_0 } from "./v1.4.0/wiml-v1-4-0";

export class Wiml_v1 implements IWiml_v1 {
    wimlClassLookup_v1: Record<WimlVer, WimlImplClass_v1> = {
        "1.0.0": WimlImpl_v1_0_0,
        "1.1.0": WimlImpl_v1_1_0,
        "1.2.0": WimlImpl_v1_2_0,
        "1.3.0": WimlImpl_v1_3_0,
        "1.4.0": WimlImpl_v1_4_0,
    };

    themeData: ThemeData_v1;
    siteData: SiteData_v1;

    constructor(data?: IWiml_v1) {
        if (data) {
            this.themeData = data.themeData;
            this.siteData = data.siteData;
        } else {
            this.themeData = new ThemeData_v1();
            this.siteData = new SiteData_v1();
        }
    }

    private getWimlInstance() {
        const wimlVer = this.themeData.meta.version;
        const versionSpecificWimlClass = this.wimlClassLookup_v1[wimlVer];

        const retVal: IWimlImpl_v1 = new versionSpecificWimlClass(this);
        return retVal;
    }

    updateMarkup(value: string) {
        const converter = new MarkupToReactConverter_v1();
        this.themeData = converter.convertMarkupToThemeData_v1(value);
    }

    createThemeDataPageComponent(pageKey: string) {
        return this.getWimlInstance().createThemeDataPageComponent(pageKey);
    }

    createThemeDataPageChildComponent(pageKey: string, componentType: string, componentKey: string, themeProps: Record<string, any>, position: string, parentComponentType: string, parentComponentKey: string, content: string) {
        return this.getWimlInstance().createThemeDataPageChildComponent(pageKey, componentType, componentKey, themeProps, position, parentComponentType, parentComponentKey, content);
    }

    addThemeDataPageChildComponentContent(pageKey: string, position: string, parentComponentType: string, parentComponentKey: string, content: string) {
        this.getWimlInstance().addThemeDataPageChildComponentContent(pageKey, position, parentComponentType, parentComponentKey, content);
    }

    moveThemeDataPageChildComponent(pageId: string, componentId: string, position: string | number) {
        return this.getWimlInstance().moveThemeDataPageChildComponent(pageId, componentId, position);
    }
    replaceStyleImportStatement(newImport: string) {
        return this.getWimlInstance().replaceStyleImportStatement(newImport);
    }

    replaceStyleCssVars(newCssVars: string) {
        return this.getWimlInstance().replaceStyleCssVars(newCssVars);
    }

    updatePageChildComponentProperty(pageId: string, componentId: string, propertyId: string, propertyExpression: string) {
        this.getWimlInstance().updatePageChildComponentProperty(pageId, componentId, propertyId, propertyExpression, this);
    }

    createNewListItem(listItemId: string, listId: string) {
        return this.getWimlInstance().createNewListItem(listItemId, listId);
    }

    getListComponentThemeData(listId: string) {
        return this.getWimlInstance().getListComponentThemeData(listId);
    }

    getPageComponentThemeData(pageId: string) {
        return this.getWimlInstance().getPageComponentThemeData(pageId);
    }

    getPageChildComponentThemeData(pageId: string, componentId: string) {
        return this.getWimlInstance().getPageChildComponentThemeData(pageId, componentId);
    }

    getPageChildListInstanceComponentThemeData(pageId: string, listInstanceComponentId: string) {
        return this.getWimlInstance().getPageChildListInstanceComponentThemeData(pageId, listInstanceComponentId);
    }

    getPageChildListInstanceChildComponentThemeData(pageId: string, listInstanceComponentId: string, componentId: string) {
        return this.getWimlInstance().getPageChildListInstanceChildComponentThemeData(pageId, listInstanceComponentId, componentId);
    }

    updateListItemComponentProperty(listId: string, listItemId: string, componentId: string, propertyId: string, propertyExpression: string) {
        this.getWimlInstance().updateListItemComponentProperty(listId, listItemId, componentId, propertyId, propertyExpression);
    }

    deleteListItem(listId: string, listItemId: string) {
        this.getWimlInstance().deleteListItem(listId, listItemId);
    }

    updateRelationship(listId: string, listItemId: string, relationshipListId: string, relationshipListItemId: string) {
        this.getWimlInstance().updateRelationship(listId, listItemId, relationshipListId, relationshipListItemId);
    }

    deleteRelationship(listId: string, listItemId: string, relationshipListId: string, relationshipListItemId: string) {
        this.getWimlInstance().deleteRelationship(listId, listItemId, relationshipListId, relationshipListItemId);
    }

    searchSiteData(query: SearchQuery_v1) {
        return this.getWimlInstance().searchSiteData(query);
    }

    convertListItemToCheckoutItemSiteData(listId: string, listItemId: string, accountActive: boolean) {
        return this.getWimlInstance().convertListItemToCheckoutItemSiteData(listId, listItemId, accountActive);
    }

}
