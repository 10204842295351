import { IWiml_v1, IWimlImpl_v1, ListInstanceComponentThemeData_v1, SearchQuery_v1, SearchResultCollection_v1, ThemeDataListQualifiedAndMergedWithComponents_v1, ThemeDataPageChildComponent_v1, ThemeDataPageWithComponents_v1 } from "../types";
import { _getThemeDataComponentId_v1_2_0, formatNodeType_v1_2_0 } from "../v1.2.0/theme-data/compiler/components";
import { addThemeDataPageChildComponentContentToMarkup_v1_3_0, addThemeDataPageChildComponentToMarkup_v1_3_0, addThemeDataPageComponentToMarkup_v1_3_0, moveThemeDataPageChildComponentToMarkup_v1_3_0, replaceStyleCssVarsInMarkup_v1_3_0, replaceStyleImportStatementInMarkup_v1_3_0 } from "../v1.3.0/theme-data/compiler/modification";
import { createNewListItem_v1_0_0, deleteListItem_v1_0_0, deleteRelationship_v1_0_0, updateListItemComponentProperty_v1_0_0, updatePageChildComponentProperty_v1_0_0, updateRelationship_v1_0_0 } from "./site-data/modification";
import { convertListItemToCheckoutItemSiteData_v1_0_0 } from "./site-data/retrieval";
import { getListComponentThemeData_v1_0_0, getPageChildListInstanceChildComponentThemeData_v1_0_0, getPageChildListInstanceComponentThemeData_v1_0_0, getPageComponentThemeData_v1_0_0 } from "./theme-data/compiler/retrieval";

export class WimlImpl_v1_0_0 implements IWimlImpl_v1 {
    private wimlData: IWiml_v1;

    constructor(data: IWiml_v1) {
        if (!data) throw new Error("data is required");
        this.wimlData = data;
    }

    createThemeDataPageComponent(pageKey: string,) {
        const newMarkup = addThemeDataPageComponentToMarkup_v1_3_0(pageKey, this.wimlData);
        this.wimlData.updateMarkup(newMarkup);


        const pageId = _getThemeDataComponentId_v1_2_0({ type: 'Page', key: pageKey });
        const newlyCreatedComponent = this.getPageComponentThemeData(pageId);

        return newlyCreatedComponent;
    }

    createThemeDataPageChildComponent(pageKey: string, componentType: string, componentKey: string, themeProps: Record<string, any>, position: string, parentComponentType: string, parentComponentKey: string, content: string,) {
        const key = componentKey;

        const newMarkup = addThemeDataPageChildComponentToMarkup_v1_3_0(pageKey, componentType, key, themeProps, position, parentComponentType, parentComponentKey, content, this.wimlData);
        this.wimlData.updateMarkup(newMarkup);

        const type = formatNodeType_v1_2_0(componentType);
        const newlyCreatedComponent = this.getPageChildComponentThemeData(`page__${pageKey}`, `${type}__${key}`);
        return newlyCreatedComponent;
    }

    addThemeDataPageChildComponentContent(pageKey: string, position: string, parentComponentType: string, parentComponentKey: string, content: string) {
        const newMarkup = addThemeDataPageChildComponentContentToMarkup_v1_3_0(pageKey, position, parentComponentType, parentComponentKey, content, this.wimlData);
        this.wimlData.updateMarkup(newMarkup);
    }

    moveThemeDataPageChildComponent(pageId: string, componentId: string, position: string | number) {
        const newMarkup = moveThemeDataPageChildComponentToMarkup_v1_3_0(pageId, componentId, position, this.wimlData);
        this.wimlData.updateMarkup(newMarkup);

        const newlyMovedComponent = this.getPageChildComponentThemeData(pageId, componentId);
        return newlyMovedComponent;
    }

    replaceStyleImportStatement(newImport: string) {
        const newMarkup = replaceStyleImportStatementInMarkup_v1_3_0(newImport, this.wimlData);
        this.wimlData.updateMarkup(newMarkup);

        return newMarkup;
    }

    replaceStyleCssVars(newCssVars: string) {
        const newMarkup = replaceStyleCssVarsInMarkup_v1_3_0(newCssVars, this.wimlData);
        this.wimlData.updateMarkup(newMarkup);

        return newMarkup;
    }

    updatePageChildComponentProperty(pageId: string, componentId: string, propertyId: string, propertyExpression: string) {
        updatePageChildComponentProperty_v1_0_0(pageId, componentId, propertyId, propertyExpression, this.wimlData);
    }

    getListComponentThemeData(listId: string): ThemeDataListQualifiedAndMergedWithComponents_v1 {
        return getListComponentThemeData_v1_0_0(listId, this.wimlData);
    }

    getPageComponentThemeData(pageId: string): ThemeDataPageWithComponents_v1 {
        return getPageComponentThemeData_v1_0_0(pageId, this.wimlData);
    }

    getPageChildComponentThemeData(pageId: string, componentId: string): ThemeDataPageChildComponent_v1 {
        return this.getPageComponentThemeData(pageId)?.components?.items?.[componentId];
    }

    getPageChildListInstanceComponentThemeData(pageId: string, listInstanceComponentId: string): ListInstanceComponentThemeData_v1 {
        return getPageChildListInstanceComponentThemeData_v1_0_0(pageId, listInstanceComponentId, this.wimlData);
    }

    getPageChildListInstanceChildComponentThemeData(pageId: string, listInstanceComponentId: string, componentId: string): ListInstanceComponentThemeData_v1 {
        return getPageChildListInstanceChildComponentThemeData_v1_0_0(pageId, listInstanceComponentId, componentId, this.wimlData);
    }



    createNewListItem(listItemId: string, listId: string) {
        const newItem = createNewListItem_v1_0_0(listItemId, listId, this.wimlData);

        return newItem;
    }

    updateListItemComponentProperty(listId: string, listItemId: string, componentId: string, propertyId: string, propertyExpression: string) {
        updateListItemComponentProperty_v1_0_0(listId, listItemId, componentId, propertyId, propertyExpression, this.wimlData);
    }

    deleteListItem(listId: string, listItemId: string) {
        deleteListItem_v1_0_0(listId, listItemId, this.wimlData);
    }

    updateRelationship(listId: string, listItemId: string, relationshipListId: string, relationshipListItemId: string) {
        updateRelationship_v1_0_0(listId, listItemId, relationshipListId, relationshipListItemId, this.wimlData);
    }


    deleteRelationship(listId: string, listItemId: string, relationshipListId: string, relationshipListItemId: string) {
        deleteRelationship_v1_0_0(listId, listItemId, relationshipListId, relationshipListItemId, this.wimlData);
    }

    searchSiteData(query: SearchQuery_v1): { results: SearchResultCollection_v1; } {
        throw new Error("Method not implemented.");
    }

    convertListItemToCheckoutItemSiteData(listId: string, listItemId: string, accountActive: boolean) {
        return convertListItemToCheckoutItemSiteData_v1_0_0(this.wimlData, listId, listItemId, accountActive);
    }
}

