import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import humps from 'humps';

import { useRef, useState, useEffect } from 'react';
import {
    Card,
    CardHeader,
    CardBody
} from 'reactstrap';
import { useSelector } from 'react-redux';
import { makeSerialilzable } from '@wip/common/lib/object-utils';

export function Preview(props) {
    const previewUrl = process.env.REACT_APP_PREVIEW_URL;
    const [isLoaded, setIsLoaded] = useState(false);

    const themes = useSelector(state => state.themes.themes);
    const iframeRef = useRef();

    useEffect(() => {
        if (isLoaded) {
            const { websiteData } = props;
            // bug started occurring in 11b55c33169cb6b4d4968498f138099bff733102 where v3 data was not serialized - potentially because domain website / style changes for wiml.
            // this is when website.style.wiml become a full-fledged class with functions - before that it was just a dict
            const serializedData = makeSerialilzable(websiteData);
            const camlized = humps.camelizeKeys(serializedData);
            // passed offline using serialization algorithm specified in spec (2.9.1)
            // ensure keys match the same case in preview mode as in live mode

            iframeRef.current.contentWindow.postMessage({ name: 'update_website', payload: { websiteData: camlized, themes } }, '*');
        } else {
            iframeRef.current.addEventListener('load', () => {
                setIsLoaded(true);
            });
        }
    }, [isLoaded, props.pendingChanges.length]);


    const preview = (
        <section className="preview-wrapper flex-grow-1 p-3 d-none d-lg-block">
            <Card className="preview shadow h-100">
                <CardHeader className="preview-header">
                    <FontAwesomeIcon size="xs" color={props.themeConfig.backgroundThirdColor} icon={faCircle} />
                    <FontAwesomeIcon size="xs" className="ml-1" color={props.themeConfig.backgroundThirdColor} icon={faCircle} />
                    <FontAwesomeIcon size="xs" className="ml-1" color={props.themeConfig.backgroundThirdColor} icon={faCircle} />
                </CardHeader>
                <CardBody className="preview-body">
                    <div className="embed-responsive h-100" >
                        <iframe ref={iframeRef} className="embed-responsive-item" src={previewUrl} />
                    </div>
                </CardBody>
            </Card>
            <style jsx>{`
          .preview-wrapper {
            background-color: ${props.themeConfig.backgroundSecondaryColor};
          }
  
          .preview-wrapper :global(.preview-header) {
            background-color: ${props.themeConfig.backgroundPrimaryColor};
            height: 40px;
          }
  
          .preview-wrapper :global(.preview-body) {
            padding:0;
          }
      `}</style>
        </section>
    );

    return preview;
}
