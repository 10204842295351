import { IThemeDataToReactConverter_v1 } from '../../types';
import { ComponentThemeData_v1_2_0 } from '../../v1.2.0/theme-data/compiler/types';
import { convertThemeDataToReact_v1_2_0 } from '../../v1.2.0/theme-data/renderer/render-components';
import { _markupToComponentThemeData_v1_3_0 } from './compiler';


export class ConvertThemeDataToReact_v1_3_0 implements IThemeDataToReactConverter_v1 {
    defaultVersion = '1.3.0';
    defaultValidVersions = ['1.3', '1.3.0'];

    public convertComponentThemeDataToRenderThemeData_v1(themeData: ComponentThemeData_v1_2_0) {
        return convertThemeDataToReact_v1_2_0(themeData);
    }
    public convertMarkupToComponentThemeData_v1(wimlString: string) {
        return _markupToComponentThemeData_v1_3_0(wimlString, this.defaultVersion, this.defaultValidVersions);
    }
}
