import * as objectUtils from '../../../../../../lib/object-utils';
import _ from 'lodash';
import { IWiml_v1, SiteDataListItem_v1, SiteDataPageComponent_v1, WimlCollection_v1 } from '../../types';
import { _getListChildComponentsThemeData_v1_0_0 } from '../../v1.0.0/theme-data/compiler/retrieval';
import { _setListItem_v1_0_0 } from '../../v1.0.0/site-data/modification';


export function _createNewListItem_v1_3_0(listId: string, wimlData: IWiml_v1): SiteDataListItem_v1 {
    const components: WimlCollection_v1<SiteDataPageComponent_v1> = {
        ids: [],
        items: {},
    };

    // set default values. if there is a heading component, set the link url to slugify of the heading text
    const listComponents = _getListChildComponentsThemeData_v1_0_0(listId, wimlData);
    const listComponentsArray = objectUtils.convertObjectToArray(listComponents);
    const linkComponent = listComponentsArray.find(c => c.type === 'Link' && c.id === 'link');

    if (linkComponent) {
        let urlPath;
        let slugifyPath;
        listId = listId.replace('list__', '');
        if (listId == 'blog') {
            urlPath = 'post';
            slugifyPath = '{{slugify(current_item.components.items.heading__title.content.data.value)}}';
        } else if (listId == 'books') {
            urlPath = 'book';
            slugifyPath = '{{slugify(current_item.components.items.heading__title.content.data.value)}}';
        } else if (listId == 'events') {
            urlPath = 'event';
            slugifyPath = '{{slugify(current_item.components.items.heading__title.content.data.value)}}';
        } else {
            const singularListId = listId.replace(/s$/, '').replace(/ies$/, 'y');
            urlPath = singularListId;
            slugifyPath = '{{slugify(current_item.components.items.heading__title.content.data.value)}}';
        }

        components.items[linkComponent.id] = {
            url: {
                data: {
                    expression: `/${urlPath}/${slugifyPath}`,

                }
            }
        };

        // this used to be down 1 line, outside of the if statement
        // but i believe it's corrected now. if i'm wrong, revert it and document why it had to be that way.
        components.ids.push(linkComponent.id);
    }

    const retVal = ({
        components,
        relationships: { lists: { ids: [] as string[], items: {} } },
        meta: {
            createdDate: new Date(),
            editedDate: new Date()
        }
    });

    return retVal;
}

export function createNewListItem_v1_3_0(listItemId: string, listId: string, wimlData: IWiml_v1): SiteDataListItem_v1 {
    const newItem = _createNewListItem_v1_3_0(listId, wimlData);

    // use https://lodash.com/docs/4.17.15#defaultsDeep
    // use defaultsDeep to assign
    _setListItem_v1_0_0(wimlData, listId, listItemId, newItem);

    return newItem;
}
